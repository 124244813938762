

/* CUSTOM CSS FOR THE APP */

.app__container {
    padding-left: 2rem;
    padding-right: 2rem;
}


.loading__spinner {
    animation: spin 1s linear infinite;
    -webkit-animation: spin 1s linear infinite;
}

.active {
    color: var(--app-primary);
    background: var(--app-dashboard);
}

.london__table {
    width: 100%;
    max-width: 30rem;
    padding: 0;
    margin: 10px 0 0 0 !important
}

.london__table thead {
    background: var(--app-primary);
}

.london__table thead th {
    padding: 5px;
    color: var(--app-theme);
}

.london__table tbody {
    background: var(--app-dashboard);
}

.london__table tbody td {
    padding: 5px;
}

.london__table tbody .action__btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.london__table tbody .action__btn svg:nth-child(1) {
    cursor: pointer;
}

.london__table tbody .action__btn svg:nth-child(2) {
    cursor: pointer;
}


@keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }



@media (min-width: 480px) {
    .app__container{
        padding-left: 2rem;
        padding-right: 2rem;
    }
}


@media (min-width: 786px) {
    .app__container{
        padding-left: 6rem;
        padding-right: 6rem;
    }
}


@media (min-width: 1024px) {
    .app__container {
        padding-left: 6rem;
        padding-right: 6rem;
    }
}