

.register__success__wrapper {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--app-theme);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    padding-left: 1rem;
    padding-right: 1rem;    
}

.change__password__container__wrapper {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--app-theme);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
}

.email__verified__wrapper {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--app-theme);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    padding-left: 1rem;
    padding-right: 1rem; 
}


.change__password__success__content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    background: var(--app-theme);
    border-radius: 16px;
}

.change__password__success__content img {
    width: 50%;
}


.register__success__content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 266px;
    background: var(--app-theme);
    border-radius: 16px;
    box-shadow: var(--app-shadow);
    padding: 20px;
}

.email__success__content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 266px;
    padding: 20px;
}

.register__success__content .head {
    font-weight: 700;
    font-size: 20px;
    color: var(--app-primary);
}

.register__success__content .desc {
    font-weight: 400;
    font-size: 14px;
    padding-top: 16px;
    line-height: 17.07px;
    color: var(--app-desc);
    text-align: center;
}

.register__success__content .desc span {
    font-weight: 600;
}

.reset__password__title {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--app-primary);
    font-size: 24px;
    font-weight: 700;
    padding-top: 1rem;
  }

  .new__auth__change__desc {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid var(--app-tertiary);
    padding: 10px;
    margin-top: 10px;
    border-radius: 4px;
    background: rgba(246, 138, 41, 0.1);
    font-size: 12px;
    color: var(--app-tertiary);
  }

  .password__auth span {
    color: rgba(114, 116, 125, 1);
     font-size: 13px;
     font-weight: 400;
   }


   .pictcher__input {
    background: rgba(237, 239, 245, 1);
    width: 100%;
    height: 40px;
    border-radius: 6px;
    border: none !important;
    margin-top: 6.06px;
    border-color: none !important;
  }
  
  .pictcher__input input {
    background: inherit;
    border-color: none !important;
    border: none !important;
  }
  


/* RESPONSIVENESS */

@media (min-width: 400px) {
    .change__password__success__content {
        width: 100%;
    }
}


@media (min-width: 480px) {
    .change__password__success__content {
        width: 100%;
    }
}

@media (min-width: 640px) {
    .register__success__content {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        height: 266px;
        background: var(--app-theme);
        border-radius: 16px;
        box-shadow: var(--app-shadow);
        padding: 20px;
    }

    .change__password__success__content {
        width: 100%;
    }
}


@media (min-width: 768px) {
    .register__success__content {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 520px;
        height: 266px;
        background: var(--app-theme);
        border-radius: 16px;
        box-shadow: var(--app-shadow);
        padding: 20px;
    }

    .change__password__success__content {
        width: 440px;
    }
}


@media (min-width: 1024px) {

    .register__success__wrapper {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        background: var(--app-theme);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        padding-left: 1rem;
        padding-right: 1rem;    
    }
    
    .register__success__content {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 520px;
        height: 266px;
        background: var(--app-theme);
        border-radius: 16px;
        box-shadow: var(--app-shadow);
        padding: 20px;
    }

    .change__password__success__content {
        width: 440px;
    }
}

@media (min-width: 1280px) {

    .register__success__wrapper {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        background: var(--app-theme);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        padding-left: 1rem;
        padding-right: 1rem;    
    }

    .register__success__content {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 520px;
        height: 266px;
        background: var(--app-theme);
        border-radius: 16px;
        box-shadow: var(--app-shadow);
        padding: 20px;
    }

    .change__password__success__content {
        width: 440px;
    }
}


@media (min-width: 1536px) {

    .register__success__wrapper {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        background: var(--app-theme);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        padding-left: 1rem;
        padding-right: 1rem;    
    }

    .register__success__content {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 520px;
        height: 266px;
        background: var(--app-theme);
        border-radius: 16px;
        box-shadow: var(--app-shadow);
        padding: 20px;
    }

    .change__password__success__content {
        width: 440px;
    }


}