

.withdraw__container {
    padding-top: 2rem;

}

.withdraw__details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 0;
    border-bottom: 1px solid var(--app-input-bg);
}

.withdraw__details p {
    font-weight: 700;
    font-size: 16px;
    color: var(--app-logo);
}

.withdraw__details span {
    font-weight: 500;
    font-size: 14px;
    color: var(--app-logo);
}